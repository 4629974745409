* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "poppins", sans-serif;
}

nav {
  display: flex;
  background-color: rgb(72, 3, 136);
  color: #fff;
  padding: 1rem 5rem;
}

.nav__logo {
  letter-spacing: 1px;
  font-size: 20px;
}

.button {
  cursor: pointer;
  color: rgb(67, 1, 128);
  font-size: 60px;
  height: 65px;
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 2rem;
}
.button:hover {
  transform: scale(1.1);
  color: #008000;
  /* box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) 0px 4px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset; */
  box-shadow: 2px 2px 2px #888888;
}
.button:hover::before {
  color: rgb(210, 105, 30);
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
  /* backdrop-filter: blur(20px); */
  background: transparent;
  gap: 1rem;
  padding: 5rem;
  /* padding-top: auto; */
}

.grid__item {
  height: 16rem;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.25);
}

.grid__item img {
  width: 100%;
  height: 100%;
  object-fit:cover;
}
.grid__item img:hover {
  cursor: pointer;
}

h1 {
  /* font-size: 2.4rem;
  font-weight: 600;
  /* margin: 2rem 5rem; */
  /* background: green; */
  /* width: 400px; */
  background-color: #008000;
  color: #fff;
  padding: 1rem 5rem;
}

@media screen and (max-width: 480px){
  nav {
    display: flex;
    padding-left: 2.3rem;
  }
  h1 {
    padding-left: 2.3rem;
  }
  .grid {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
  }
  .grid__item{
    height: 12rem;

  }
}
